/* eslint-disable react/prop-types */
import './XpektorForm.scss';
import './MinimalForm.scss';
import './ClassicForm.scss';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export function XpektorForm({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="xpektor-form">
      {children}
    </form>
  );
}

export function MinimalForm({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="minimal-form">
      {children}
    </form>
  );
}

export function ClassicForm({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="classic-form">
      {children}
    </form>
  );
}

export function FormInput({
  label,
  type,
  placeholder,
  value,
  onChange,
  icon,
  onIconClick,
  infoText,
  infoOk,
}) {
  const [focused, setFocused] = useState(false);
  return (
    <div className={`form-input-container ${focused ? 'input-focus' : ''}`}>
      <label htmlFor={label}>{label}</label>
      <div className="form-input">
        <input
          id={label}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e);
            if (e.target.value) setFocused(true);
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            if (value?.length === 0) setFocused(false);
          }}
        />
        {icon ? (
          <FontAwesomeIcon
            className={`form-input-icon ${onIconClick ? 'clickable-icon' : ''}`}
            icon={icon}
            onClick={onIconClick}
          />
        ) : (
          ''
        )}
      </div>
      <div className={`input-info-container ${infoOk && 'input-info-ok'}`}>
        {infoText ? (
          <>
            <FontAwesomeIcon className="input-info-icon" icon={faCircleInfo} />
            <p>{infoText}</p>{' '}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export function FormTextArea({ label, value, onChange, infoText, rows }) {
  const [focused, setFocused] = useState(false);

  return (
    <div className={`form-text-area-container ${focused ? 'input-focus' : ''}`}>
      <label htmlFor={label}>{label}</label>
      <textarea
        id={label}
        value={value}
        rows={rows}
        onChange={(e) => onChange(e)}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          if (value?.length === 0) setFocused(false);
        }}
      />
      <div className="input-info-container">
        {infoText ? (
          <>
            <FontAwesomeIcon className="input-info-icon" icon={faCircleInfo} />
            <p>{infoText}</p>{' '}
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export function FormButton({ text, disabled }) {
  return (
    <input
      className="xpektor-form-submit-button"
      type="submit"
      value={text}
      disabled={disabled}
    />
  );
}
